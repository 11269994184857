import Stack from 'components/dist/atoms/Stack';
import useInfiniteScroll, { ScrollDirection } from 'react-easy-infinite-scroll-hook';
import { Role, RoleLenderGroupLevel } from 'src/backend';
import { useMessagesContext } from 'src/contexts/messages-context';
import { MessageDtoExtended } from 'src/services/messageApi';
import { getUserDisplayName } from 'src/utils/user/get-user-display-name';

import { BorrowerPortalMessagesSearchResultsListItem } from '../borrower-portal-messages-search-results-list-item';
import { useBorrowerPortalMessagesSearchResultsList } from './borrower-portal-messages-search-results-list.state';

interface BorrowerPortalMessagesSearchResultsListProps {
    messages: MessageDtoExtended[];
    onNext: (direction: ScrollDirection) => Promise<void>;
    hasMore: boolean;
    filterQuery: string;
    loggedInUserId: string;
    loggedRoleGroup: RoleLenderGroupLevel;
    mode: "THREAD" | "LOAN";
    isDraft?: boolean;
}

export const BorrowerPortalMessagesSearchResultsList = (props: BorrowerPortalMessagesSearchResultsListProps) => {
    const messageContext = useMessagesContext();
    const state = useBorrowerPortalMessagesSearchResultsList();

    const ref = useInfiniteScroll<HTMLDivElement>({
        next: props.onNext,
        rowCount: props.messages.length,
        scrollThreshold: 100,
        hasMore: {
            down: props.hasMore,
        },
    });

    return <Stack
        ref={ref}
        className='flex-1 overflow-y-auto scrollbar-stable'
        space="md">
        {props.messages.map((row) => <BorrowerPortalMessagesSearchResultsListItem
            key={row.id}
            important={row.important}
            title={state.getRowTitle(props.mode, row)}
            date={row.processedTime ? row.processedTime : row.createdDate}
            highlightText={props.filterQuery}
            documents={row.documents}
            loggedInUserId={props.loggedInUserId}
            loggedRoleGroup={props.loggedRoleGroup}
            generationType={row.generationType}
            onMessageClick={() => {
                props.isDraft ? messageContext.onNavigateToDraftMessage(row) : messageContext.onNavigateToMessage(row)
            }}
            onDeleteDraft={props.isDraft ? () => messageContext.onDeleteDraftMessage(row.id, true) : undefined}
            unread={!row.isReadByMe}
            users={props.mode === "LOAN" ? row.messageThread?.users.map(user => user.user) : [row.from]}
            preview={row.textPreview}
            name={props.mode === "LOAN" ? getUserDisplayName(row.from) : ""}
            isDraft={props.isDraft}
            className=' border border-gray-neutral-80 bg-white-neutral-32 rounded-md' />)}
    </Stack>
}