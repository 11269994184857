import { useCallback, useEffect, useState } from "react";
import { ScrollDirection } from 'react-easy-infinite-scroll-hook';
import { useMessagesContext } from "src/contexts/messages-context";
import { MessageDtoExtended, useLazySearchMessagesQuery } from "src/services/messageApi";
import { useDebounceValue, useIsMounted } from "usehooks-ts";

interface Pagination {
    rows: MessageDtoExtended[];
    hasMore: boolean;
    page: number;
    scrollLocked: boolean;
}

const initialPagination: Pagination = {
    rows: [],
    hasMore: true,
    page: 0,
    scrollLocked: false,
};

export const useBorrowerPortalMessagesSearchResultsState = () => {

    const [pagination, setPagination] = useState<Pagination>(initialPagination);
    const [searchMessages, { isFetching: isLoading }] = useLazySearchMessagesQuery();
    const messagesContext = useMessagesContext();
    const [debouncedValue] = useDebounceValue(messagesContext.filterQuery, 600);
    const isMounted = useIsMounted();

    const next = useCallback(async (_: ScrollDirection, page?: number) => {
        if (isMounted()) {
            setPagination(prevState => ({
                ...(page === 0 ? initialPagination : prevState),
                scrollLocked: true,
            }));
        }
        const result = await searchMessages({
            textSearch: debouncedValue,
            order: messagesContext.filterOrder,
            threadId: messagesContext.activeThread?.id ?? '',
            page: typeof page !== 'undefined' ? page : pagination.page,
            loanId: messagesContext.loan?.id,
            iLabels: messagesContext.filterLabel ? [messagesContext.filterLabel] : [],
        }).unwrap();
        if (isMounted()) {
            setPagination(prevState => ({
                ...prevState,
                rows: [...prevState.rows, ...result.content.flat()],
                hasMore: result.content.length >= 20,
                page: prevState.page + 1,
                scrollLocked: false,
            }));
        }
    }, [debouncedValue, isMounted, messagesContext.activeThread?.id, messagesContext.filterLabel, messagesContext.filterOrder, messagesContext.loan?.id, pagination.page, searchMessages]);

    useEffect(() => {
        next(ScrollDirection.DOWN, 0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedValue, messagesContext.filterLabel, messagesContext.filterOrder]);

    return {
        next,
        isLoading: isLoading && pagination.rows.length === 0,
        hasMore: pagination.hasMore && !pagination.scrollLocked,
        searchLevel: messagesContext.activeThread ? 'messages' : 'threads',
        loggedInUserId: messagesContext.loggedInUserId,
        loggedRoleGroup: messagesContext.loggedRoleGroup,
        rows: pagination.rows,
        filterQuery: debouncedValue,
        mode: messagesContext.activeThread ? 'THREAD' : 'LOAN',
    } as const;
}