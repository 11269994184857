import BellNotification, { BellNotificationItemProps } from "components/dist/organisms/BellNotification";
import { useGetDocumentWithDownloadUrlQuery } from "src/services/documentApi";
import { AppUserDTO2Extended } from "src/types/user";

interface Props extends Omit<BellNotificationItemProps, 'user'> {
    user: AppUserDTO2Extended;
    hasLink: boolean;
    onRead: (id: string) => void;
}

export const BellNotificationListItem = (props: Props) => {
    const { currentData: avatarDocumentData } = useGetDocumentWithDownloadUrlQuery({
        id: props.user.avatarDocId
    }, {
        skip: !props.user.avatarDocId
    });

    const onClick = () => {
        props.onRead(props.id);
        props.onClick(props.id);
    };

    return <BellNotification.Item
        user={{
            initials: props.user.initials,
            groupRole: props.user.loggedRoleGroup,
            avatarUrl: avatarDocumentData?.downloadUrl.toString()
        }}
        onClick={props.hasLink ? onClick : undefined}
        id={props.id}
        dateTimeNotification={props.dateTimeNotification}
        isRead={props.isRead}
        key={props.id} title={props.title}
        itemTitles={props.itemTitles ?? []}
        loanTitle={props.loanTitle}
        loanId={props.loanId}
        threadTitle={props.threadTitle}
        messagePreview={props.messagePreview}
    />
};